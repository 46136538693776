import React from 'react'

import './Hamburger.css'
import './hamburgers.css'

export default class Hamburger extends React.Component {
    state = { isInverted: false }

    get isOnTopOfImage() {
        return document.elementsFromPoint(0, 70).some(element =>
            element.classList.contains("Section--backgroundImage")
        )
    }

    updateVisibility = () => {
        let { isInverted } = this.state
        let shouldBeInverted = this.isOnTopOfImage
        if (isInverted !== shouldBeInverted) {
            this.setState({ isInverted: shouldBeInverted })
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.updateVisibility);
        this.updateVisibility();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.updateVisibility);
    }

    toggle = () => {
        this.props.setShown(!this.props.isShown)
    }

    render() {
        return <button
            id="hamburger"
            className={`Hamburger hamburger hamburger--spin ${this.props.isShown && "is-active"}`}
            type="button"
            onClick={this.toggle}>
            <span className={`hamburger-box ${(this.state.isInverted || this.props.isShown) && "Hamburger--inverted"}`}>
                <span className="hamburger-inner" />
            </span>
        </button>
    }
}
