import React from 'react'

import './OverlayMenu.css'
import Container from 'react-bootstrap/Container';
import NavLink from './NavLink.js';
import Row from 'react-bootstrap/Row';

import NavigationLinks from './content/NavigationLinks.js';

export default class OverlayMenu extends React.Component {
    render() {
        return <div className={`OverlayMenu ${this.props.isShown && "shown"}`}>
            <div className={`OverlayMenuContent ${this.props.isShown && "shown"}`}>
                <Container>
                    <Row className="justify-content-center gy-4">
                        {
                            (this.props.links || NavigationLinks).map((link, i) => {
                                return <NavLink
                                    key={link.url}
                                    href={link.url}
                                    onClick={() => this.props.setShown(false)}
                                    className="HamburgerLink"
                                    fontSize="18pt">
                                    {link.title}
                                </NavLink>
                            })
                        }
                    </Row>
                </Container>
            </div>
        </div>
    }
}
