import React from 'react';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavLink from './NavLink.js';

import NavigationLinks from './content/NavigationLinks.js';

export default class NavBar extends React.Component {
    state = {
        isHidden: true,
        isOpaque: false,
        blurBackground: false,
    };

    get isHeroScrolledIntoView() {
        let heros = document.querySelectorAll("#HeroLinks");
        if (heros.length == 0) { return false; }
        let hero = document.querySelectorAll("#HeroLinks")[0];
        let heroRect = hero.getBoundingClientRect();
        return heroRect.top < window.innerHeight && heroRect.bottom >= 0;
    }

    get isOnTopOfImage() {
        if (this.props.forceInitialBlur) {
            console.log(document.elementsFromPoint(0, 60));
        }
        return document.elementsFromPoint(0, 60).some(element =>
            element.classList.contains("Section--backgroundImage") ||
            element.classList.contains("ForceBlurNavBar")
        )
    }

    get isOnTopOfContent() {
        // A fairly ridiculous heuristic to figure out if the bar is going to
        // overlap interstitial text and therefore we should blur the
        // background to improve legibility.
        return document.elementsFromPoint(0, 60).some(element =>
            element.classList.contains("ForceBlurNavBar") || (
                element.classList.contains("SectionContent") &&
                    Array.from(element.childNodes).some(child =>
                        child.classList.contains("Interstitial") &&
                            child.getBoundingClientRect().top <= 0
                    )
            )
        )
    }

    updateVisibility = () => {
        let { isHidden, isOpaque, blurBackground } = this.state
        let shouldBeHidden = this.isHeroScrolledIntoView
        let shouldBeOpaque = !this.isOnTopOfImage
        let shouldBlur = this.isOnTopOfContent
        if (isHidden !== shouldBeHidden || isOpaque !== shouldBeOpaque || blurBackground !== shouldBlur) {
            this.setState({
                isHidden: shouldBeHidden,
                isOpaque: shouldBeOpaque,
                blurBackground: shouldBlur,
            })
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.updateVisibility);
        this.updateVisibility();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.updateVisibility);
    }

    render() {
        return <Navbar
            collapseOnSelect
            expand="sm"
            variant="light"
            fixed="top"
            className={`
                NavBar-animation
                ${!this.state.isHidden && (this.state.isOpaque ? "NavBar-over-rest" : "NavBar-over-hero")}
                ${!this.state.isHidden && this.state.blurBackground && "NavBar-blur"}
                ${this.props.autohide && "NavBar-autohide"}
            `}
            style={{
                opacity: this.state.isHidden ? 0 : 1
            }}>
            <Container style={{
                maxWidth: "1000px",
                paddingLeft: "5%",
                paddingRight: "5%",
            }}>
                {
                    (this.props.links || NavigationLinks).map((link, i) => {
                        return <NavLink
                            key={link.url}
                            href={link.url}
                            className="NavBarLink">
                            {link.title}
                        </NavLink>
                    })
                }
            </Container>
        </Navbar>
    }
}
