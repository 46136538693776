import React, { useState, useEffect } from 'react'

import './RSVPFormSection.css'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Row from 'react-bootstrap/Row'
import Section from '../Section.js'
import TextSectionContent from '../TextSectionContent.js'
import { HeaderText, FancyHeaderText } from '../HeaderText.js'
import { useUserData, I } from '../UserData.js'
import API from '../API.js'
import Spinner from 'react-bootstrap/Spinner'

function RSVPOption(props) {
    return (
        <Col onClick={props.onClick} className={[
            "RSVPOption",
            props.type,
            props.isSelected ? "Selected" : null,
        ]} lg={4} xl={3}>
            <FancyHeaderText className="RSVPOptionTitle" fontSize={60}>
                { props.type }
            </FancyHeaderText>
            { props.subtitle }
        </Col>
    )
}

function YesNoOption(props) {
    return (
        <Col onClick={props.onClick} className={[
            "RSVPOption",
            props.type,
            props.isSelected ? "Selected" : null,
            props.className,
        ]} sm={props.size || 3}>
            { props.content || props.type }
        </Col>
    )
}

function Checkbox(props) {
    return (
        <div onClick={props.onClick}
            className={`
                RSVPOption
                ${props.isSelected ? "Yes" : "No"}
                ${props.toggles || props.isSelected ? "Selected" : null }
            `}
            style={{
                padding: 0,
                marginTop: "10px",
                marginBottom: "10px",
                width: "35px",
                height: "35px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                ...props.style,
            }}>
            <If condition={props.isSelected}>
                <img
                    src="./img/check-mark.svg"
                    alt="Check mark"
                    style={{
                        width: "14px",
                        filter: "invert(36%) sepia(71%) saturate(613%) hue-rotate(79deg) brightness(91%) contrast(90%)",
                    }} />
            </If>
            <If condition={!props.isSelected && props.toggles}>
                <img
                    src="./img/x-mark.svg"
                    alt="X mark"
                    style={{
                        width: "14px",
                        filter: "invert(25%) sepia(18%) saturate(5497%) hue-rotate(335deg) brightness(90%) contrast(101%)",
                    }} />
            </If>
        </div>
    )
}

function SmallText(props) {
    return <div style={{
        fontSize: props.size || "12pt",
        paddingBottom: props.paddingBottom,
        paddingTop: props.paddingTop,
        ...props.style,
    }}>
        { props.children }
    </div>
}

function If(props) {
    return props.condition ? props.children : null
}

function VaccineFormSection(props) {
    return <>
        Are you fully vaccinated against COVID-19?
        <Row className="RSVPYesNo justify-content-center align-items-center RSVPFormRow">
            <YesNoOption type="Yes"
                isSelected={ props.state.isVaxxed === true }
                onClick={ () => props.setState(state => ({ ...state, isVaxxed: true })) }
            />
            <Col className="d-none d-sm-block" sm={1}>or</Col>
            <YesNoOption type="No"
                isSelected={ props.state.isVaxxed === false }
                onClick={ () => props.setState(state => ({ ...state, isVaxxed: false })) }
            />
        </Row>

        <If condition={props.state.isVaxxed === true}>
            <SmallText>
                Wonderful! All other attendees will be fully vaccinated as well.
            </SmallText>
        </If>

        <If condition={props.state.isVaxxed === false}>
            <SmallText paddingBottom="80px">
                To ensure everyone's safety, we are requiring that all guests be fully vaccinated against COVID-19.
            </SmallText>

            Will you be fully vaccinated by October 3rd, 2021?
            <Row className="RSVPYesNo justify-content-center align-items-center RSVPFormRow">
                <YesNoOption type="Yes"
                    isSelected={ props.state.willBeVaxxed === true }
                    onClick={ () => props.setState(state => ({ ...state, willBeVaxxed: true })) }
                />
                <Col className="d-none d-sm-block" sm={1}>or</Col>
                <YesNoOption type="No"
                    isSelected={ props.state.willBeVaxxed === false }
                    onClick={ () => props.setState(state => ({ ...state, willBeVaxxed: false })) }
                />
            </Row>
        </If>

        <If condition={props.state.isVaxxed === false && props.state.willBeVaxxed === true}>
            <SmallText>
                Great! We'll follow up with you closer to the wedding.
            </SmallText>
        </If>

        <If condition={props.state.isVaxxed === false && props.state.willBeVaxxed === false}>
            <Separator />
            <SmallText size="14pt">
                We understand it may not be possible to get the vaccine in time for our wedding. However, for your safety and
                ours, we respectfully ask that you do not attend in person.
            </SmallText>
        </If>
    </>
}

function Separator() {
    return <hr class="Separator" />
}

function OtherAttendeesFormSection(props) {
    const [userData] = useUserData()
    return <>
        <If condition={ userData.num_people > 1 }>
            <Separator />
            Will you both be attending?
            <Row className="RSVPYesNo justify-content-center align-items-center RSVPFormRow">
                <YesNoOption type="Yes"
                    isSelected={ props.state.allAttending === true }
                    onClick={ () => props.setState(state => ({ ...state, allAttending: true })) }
                />
                <Col className="d-none d-sm-block" sm={1}>or</Col>
                <YesNoOption type="No"
                    isSelected={ props.state.allAttending === false }
                    onClick={ () => props.setState(state => ({ ...state, allAttending: false })) }
                />
            </Row>
        </If>
        <If condition={props.state.allAttending === true || props.state.allAttending === false || userData.num_people == 1}>
            <Separator />
            <SmallText size="14pt" paddingBottom="10px">
                {userData.familiar_name}, this invitation is just for you. To minimize COVID risk, please don't extend it to
                anyone else.
            </SmallText>
            <SmallText size="14pt" paddingBottom="10px">
                While we love little ones, our wedding will be adults-only due to venue restrictions.
            </SmallText>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "14pt",
                paddingTop: "10px",
            }}>
                <Checkbox
                    onClick={() => props.setState(state => ({ ...state, agreeNoUninvitedGuests: !state.agreeNoUninvitedGuests }))}
                    isSelected={props.state.agreeNoUninvitedGuests}
                />
                <span style={{
                    paddingLeft: "10px",
                    fontSize: "16pt",
                }}>
                    Got it!
                </span>
            </div>
        </If>
        <If condition={props.state.agreeNoUninvitedGuests}>
            <Separator />
        </If>
    </>
}

function PerEventRSVPFormSection(props) {
    return <div>
        <div style={{ marginBottom: "20px" }}>
            Will you attend these other events?
        </div>

        <SmallText paddingBottom="10px">
            We'd love if you could join us for these other festivities! These will be held separately from the wedding. See the schedule below for details.
        </SmallText>
        <Row className="justify-content-center">
            <SmallText size="12pt" paddingBottom="20px" style={{ maxWidth: "700px" }}>
                They will be held outdoors, but at smaller venues, which may pose a higher COVID risk. We totally understand if you prefer not to attend!
            </SmallText>
        </Row>
        {
            props.eventList
                .filter(event => event.is_restricted)
                .map(event => <Row className="RSVPYesNo justify-content-center">
                    <div style={{
                        width: "280px",
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                    }}>
                        <Checkbox
                            toggles={true}
                            isSelected={ props.state.perEventRSVPs[event.pk] !== false }
                            style={{ marginRight: "10px" }}
                            onClick={ () => props.setState(state => {
                                const oldValue = state.perEventRSVPs[event.pk]
                                const newValue = oldValue === false ? true : false
                                const newRSVPs = { ...state.perEventRSVPs, [event.pk]: newValue }
                                return { ...state, perEventRSVPs: newRSVPs }
                            }) }
                        />
                        {event.name}
                    </div>
                </Row>
            )
        }
        <Separator />
    </div>
}

function RSVPForm(props) {
    return <Row className="justify-content-center" style={{ textAlign: "center" }}>
        <Form.Group>
            <VaccineFormSection state={props.state} setState={props.setState} />
            <If condition={isPastVaccineSection(props.state)}>
                <OtherAttendeesFormSection state={props.state} setState={props.setState} />
            </If>
            <If condition={isPastVaccineSection(props.state) && isPastOtherAttendeesSection(props.state) && props.eventList.some(event => event.is_restricted)}>
                <PerEventRSVPFormSection state={props.state} setState={props.setState} eventList={props.eventList} />
            </If>
        </Form.Group>
    </Row>
}

function RSVPYay() {
    return <span className="RSVPRIP">
        <div style={{
            fontSize: "18pt",
            paddingBottom: "10px",
        }}>
            We're so excited to see you!
        </div>
        <SmallText paddingBottom="30px">
            Please fill out the rest of this form to submit your RSVP.
        </SmallText>
    </span>
}

function RSVPRIP() {
    return <span className="RSVPRIP">
        <div style={{
            fontSize: "18pt",
            paddingBottom: "30px",
        }}>
            We're sad to hear you can't make it.
        </div>
        <SmallText paddingBottom="30px">
            These are difficult times, and we recognize that the circumstances may not allow you to safely attend our wedding.
            We will sorely miss you, and look forward to celebrating with you in person very soon!
        </SmallText>
        <div style={{
            fontSize: "11pt",
        }}>
            (We expect you to hold an all-day puja in our honor though)
        </div>
        <Separator />
    </span>
}

function FormRow(props) {
    return <Row className="justify-content-center RSVPFormRow" xl={2} lg={1} md={1}>
        { props.children }
    </Row>
}

function isPastYesNoSection(state) {
    return state.rsvp !== null
}

function isPastVaccineSection(state) {
    return state.isVaxxed || state.willBeVaxxed
}

function isPastOtherAttendeesSection(state) {
    return state.agreeNoUninvitedGuests
}

function isPastPerEventRSVPSection(state) {
    return state.answeredAllEventRSVPs
}

function isReadyToSubmit(state) {
    if (state.rsvp === false) {
        return true
    }
    return isPastYesNoSection(state)
        && isPastVaccineSection(state)
        && isPastOtherAttendeesSection(state)
}

export default function RSVPFormSection(props) {
    const [userData] = useUserData()
    let [state, setState] = useState({
        perEventRSVPs: {},
    })
    let [isLoading, setLoading] = useState(false)
    let [error, setError] = useState(null)
    let [message, setMessage] = useState("")
    let [lastRSVP, setLastRSVP] = useState(null)
    let [isLoadingLastRSVP, setLoadingLastRSVP] = useState(true)

    React.useEffect(() => {
        if (userData) {
            setLoadingLastRSVP(true)
            API.lastRSVP(userData)
                .onSuccess(data => setLastRSVP(data))
                .onError(() => setLastRSVP(null))
                .finally(() => setLoadingLastRSVP(false))
                .send()
        } else {
            setLoadingLastRSVP(false)
        }
    }, [])

    const onSubmit = event => {
        event.preventDefault()
        setLoading(true)
        setError(null)
        API.submitRSVP(
            userData,
            state.rsvp,
            state.isVaxxed,
            state.allAttending || userData.num_people === 1,
            state.perEventRSVPs,
            message
        )
        .onSuccess(_ => {
            setLastRSVP({
                will_attend: state.rsvp,
                events: props.eventList.filter(event => event.is_restricted).map(event => ({
                    event__name: event.name,
                    will_attend: state.perEventRSVPs[event.pk] !== false,
                }))
            })
            window.scrollTo(0, 0)
        })
        .onError(() => {
            setError("An error occurred. Please try again.")
        })
        .finally(() => setLoading(false))
        .send()
    }

    if (isLoadingLastRSVP || props.isLoadingEvents) {
        return <Section id={props.id} isFixedHeight={false}>
            <TextSectionContent title="RSVP">
                <Spinner
                    as="span"
                    animation="border"
                    size="lg"
                    role="status" />
            </TextSectionContent>
        </Section>
    }

    if (lastRSVP !== null && (lastRSVP.will_attend === true || lastRSVP.will_attend === false)) {
        let attending = lastRSVP.events ? lastRSVP.events.filter(event => event.will_attend) : []
        attending = props.eventList.filter(event => !event.is_restricted).map(event => ({
            event__name: event.name,
        })).concat(attending)
        const notAttending = lastRSVP.events ? lastRSVP.events.filter(event => !event.will_attend) : []
        return <Section id={props.id} isFixedHeight={false}>
            <TextSectionContent title="RSVP">
                <div>We've received your RSVP.</div>
                <div style={{
                    fontSize: "13pt",
                    paddingTop: "10px",
                    paddingBottom: "20px",
                }}>
                    {lastRSVP.will_attend ? "We're excited to see you!" : "You are not attending."}
                </div>
                <If condition={lastRSVP.will_attend}>
                    <Row className="justify-content-center">
                        <Row style={{
                            maxWidth: "600px",
                            paddingTop: "20px",
                            paddingBottom: "20px",
                        }}>
                            <If condition={attending.length > 0}>
                                <Col>
                                    <HeaderText fontSize="13pt">
                                        Attending
                                    </HeaderText>
                                    <Row style={{
                                        fontSize: "13pt",
                                        justifyContent: "center",
                                        paddingTop: "20px",
                                        paddingBottom: "20px",
                                    }}>
                                        { attending.map(event => <div>{event.event__name}</div>) }
                                    </Row>
                                </Col>
                            </If>
                            <If condition={notAttending.length > 0}>
                                <Col>
                                    <HeaderText fontSize="13pt">
                                        Not Attending
                                    </HeaderText>
                                    <Row style={{
                                        fontSize: "13pt",
                                        justifyContent: "center",
                                        paddingTop: "20px",
                                        paddingBottom: "20px",
                                    }}>
                                        { notAttending.map(event => <div>{event.event__name}</div>) }
                                    </Row>
                                </Col>
                            </If>
                        </Row>
                    </Row>
                </If>
            </TextSectionContent>
        </Section>
    }

    return <Section id={props.id} isFixedHeight={false}>
        <TextSectionContent title="RSVP">
            <div style={{ paddingBottom: "30px" }}>
                {userData.familiar_name}, will you attend our wedding?
            </div>
            <Form style={{ textAlign: "left" }} onSubmit={onSubmit}>
                <Row className="RSVPYesNo justify-content-center align-items-center RSVPFormRow">
                    <RSVPOption type="Yes"
                        subtitle={`${ I(userData, { titleCase: true }) } joyfully accept`}
                        isSelected={ state.rsvp === true }
                        onClick={ () => setState(state => ({ ...state, rsvp: true })) }
                    />
                    <Col className="d-none d-lg-block" lg={1}>or</Col>
                    <RSVPOption type="No"
                        subtitle={`${ I(userData, { titleCase: true }) } regretfully decline`}
                        isSelected={ state.rsvp === false }
                        onClick={ () => setState(state => ({ ...state, rsvp: false })) }
                    />
                </Row>
                <If condition={state.rsvp === true}>
                    <FormRow>
                        <RSVPYay />
                    </FormRow>
                    <FormRow>
                        <RSVPForm state={state} setState={setState} eventList={props.eventList} />
                    </FormRow>
                </If>
                <If condition={state.rsvp === false}>
                    <FormRow>
                        <RSVPRIP />
                    </FormRow>
                </If>
                <If condition={isReadyToSubmit(state)}>
                    <div className="RSVPSubmitSectionContainer">
                        <div className="RSVPFormRow RSVPSubmitSection">
                            <Form.Label>Any other notes for us?</Form.Label>
                            <FormControl
                                as="textarea"
                                rows={3}
                                style={{ marginBottom: "80px" }}
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                            />
                            <If condition={error !== null}>
                                <div style={{
                                    color: "red",
                                    fontSize: "12pt",
                                    paddingBottom: "10px",
                                }}>
                                    { error }
                                </div>
                            </If>
                            <Button variant="primary" type="submit" className="RSVPSubmit" disabled={isLoading}>
                                {
                                    isLoading
                                        ? <Spinner
                                            as="span"
                                            animation="border"
                                            size="lg"
                                            role="status" />
                                        : <>Send RSVP</>
                                }
                            </Button>
                        </div>
                    </div>
                </If>
            </Form>
        </TextSectionContent>
    </Section>
}
