import React, {useEffect} from 'react'

import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import smartquotes from 'smartquotes'
import Helmet from 'react-helmet'
import Main from './Main.js'

export default function App() {
    useEffect(() => { smartquotes().listen() }, [])
    return (
        <div className="App">
            <Helmet>
                <meta name='viewport' content='initial-scale=1, viewport-fit=cover' />
                <link rel="stylesheet" href="https://use.typekit.net/jkk8lvc.css" />
                <script async defer data-domain="prachieandanshu.com" src="https://plausible.selene.pw/js/plausible.js"></script>

            </Helmet>

            <Main />
        </div>
    );
}
