import React from 'react'

import Section from '../Section.js'
import SectionSubtitle from '../SectionSubtitle.js'
import TextSectionContent from '../TextSectionContent.js'
import { Blurb, BlurbContainer } from '../Blurb.js'

export default class StorySection extends React.Component {
    render() {
        return <Section id={this.props.id} height="600px" isFixedHeight={false}>
            <TextSectionContent title="Our Story">
            <SectionSubtitle>It's been in the making a long, <i>long</i> time.</SectionSubtitle>
            <BlurbContainer>
                <Blurb title="Jan 4, 1994">
                    Yes, that's right! We met in preschool when we were just 2 years old. We have our parents to thank for sparking a friendship between our families that would last for decades to come.
                </Blurb>
                <Blurb title="Jun 18, 2019">
                    We decided we couldn't ignore our feelings any longer, and had our first real date at 55 South in San Jose. We were struck by just how natural it felt. We knew this was something special.
                </Blurb>
                <Blurb title="May 20, 2021">
                    It was clear to us that we were meant for each other. So, on an evening stroll in the vineyards of Laujor Estate, Anshu asked Prachie if she would spend the rest of her life with him…
                </Blurb>
            </BlurbContainer>
            </TextSectionContent>
        </Section>
    }
}
