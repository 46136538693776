import React from 'react'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { HeaderText } from './HeaderText.js';

class BlurbContainer extends React.Component {
    render() {
        return <Container>
            <Row>
                { this.props.children }
            </Row>
        </Container>
    }
}

class Blurb extends React.Component {
    render() {
        return <Col lg>
        <div className="Blurb">
            <div>
                <HeaderText fontSize="13pt" style={{ paddingBottom: this.props.titleSpacing ?? "30px" }}>
                    { this.props.title }
                </HeaderText>
            </div>
            <div style={{
                fontSize: "13pt",
            }}>
                { this.props.children }
            </div>
            </div>
        </Col>
    }
}

export { Blurb, BlurbContainer };
