import React from 'react'

import Button from 'react-bootstrap/Button'
import Section from '../Section.js'
import TextSectionContent from '../TextSectionContent.js'

export default function RegistrySection(props) {
    return <Section id={props.id} isFixedHeight={false}>
        <TextSectionContent title="Gift Registry">
            <div style={{
                display: "flex",
                justifyContent: "center",
            }}>
                <div style={{
                    fontSize: "13pt",
                    maxWidth: "500px",
                    }}>
                    <p>Your presence and blessings at our wedding are all we wish for! Please don't feel obligated to give us any gifts.</p>
                    <p>If you do insist on a gift, please don't bring boxed gifts to the wedding. Instead, you can contribute to our honeymoon fund. Thank you very much!</p>
                    <Button variant="primary" size="lg" href="https://www.honeyfund.com/wedding/chimala-banthia-10-03-2021">
                        View Honeymoon Fund
                    </Button>
                </div>
            </div>
        </TextSectionContent>
    </Section>
}
