import React, { useState } from 'react'

import './EventScheduleSection.css'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Section from '../Section.js'
import Spinner from 'react-bootstrap/Spinner'
import TextSectionContent from '../TextSectionContent.js'
import countdown from 'countdown'
import { Blurb, BlurbContainer } from '../Blurb.js'
import { FancyHeaderText } from '../HeaderText.js'
import { HeaderText } from '../HeaderText.js'

function formatRelative(date) {
    return "in " + countdown(new Date(), date, countdown.MONTHS | countdown.DAYS).toString()
}

function LoadingIndicator() {
    return <div style={{ height: "1000px" }}>
        <Spinner as="span" animation="border" size="lg" role="status" />
    </div>
}

export function HaldiDetails() {
    return <>
        <p>
            A haldi ceremony is a smaller event for close family and friends of the bride and groom (that’s you!). Everyone wears yellow, and the family and friends put yellow turmeric paste on the bride and groom to bless them.
        </p>
        <p>
            <b>Dress code:</b> Casual dress or semi-formal. We’d encourage a shade of yellow, as that’s customary for a haldi. Jeans + a polo or button-down, or a sundress are totally fine for this event.
        </p>
        <p>
            <b>Videos:</b>
            <ul>
                <li><a href="https://www.youtube.com/watch?v=vFGSms5jwOc">Example haldi so you can see the vibe</a> (recommend watch at 1.75x speed and you’ll get the picture in the first ~5 minutes, don’t have to keep watching after that)</li>
                <li><a href="https://www.youtube.com/watch?v=R0yQo8g4jSc">Informational video</a> about the significance of the ceremony</li>
            </ul>
        </p>
    </>
}

export function SangeetDetails() {
    return <>
        <p>
            Sangeet literally means “music”, and when it is in an Indian wedding it translates to Music Night or Musical Party! There will be musical and dance performances (please let us know if you would be able to perform, we’d love to have you!), casual dancing, and dinner. It’s time to hype up before the big day!
        </p>
        <p>
            <b>Dress code:</b> Semi-formal. You can wear formal Indian wear or western slacks + button up, or a sundress. Any color is fine, but we’d encourage bright colors as that’s customary for a sangeet! If you have any questions about dress code, please contact the Maid of Honor (Reeti, 408 781 1391).
        </p>
    </>
}

export function AfterpartyDetails() {
    return <>
        <p>
            Get ready to PARTY!! We’ll have carbs and alcohol and sleeping bags galore. We have booked a PARTY BUS to take us from the venue to the afterparty. You don’t want to miss it!
        </p>
        <p>
            The afterparty is hosted by Reeti, the Maid of Honor, at her house in Milpitas. There's plenty of street parking, so
            you can leave your car there and Uber/Lyft/carpool to the wedding venue, then ride the party bus back.
        </p>
        <p>
            Since we'll be up late, we don't encourage driving after. We'll have room for you to crash at Reeti's; just let us
            know if you plan to do that!
        </p>
    </>
}

export function detailsForEvent(event) {
    let details
    if (event.name == "Haldi") {
        details = <HaldiDetails />
    } else if (event.name.includes("Sangeet")) {
        details = <SangeetDetails />
    } else if (event.name == "Afterparty") {
        details = <AfterpartyDetails />
    }
    return details
}

function Event(props) {
    const [showDetails, setShowDetails] = useState(false)
    let width = 4
    let details = detailsForEvent(props.event)
    let detailsSection
    if (details) {
        detailsSection = <>
            <Row className={ showDetails ? "EventDetails EventDetails-shown" : "EventDetails EventDetails-hidden" }>
                <hr />
                { details }
            </Row>
            <Row style={{ paddingTop: "15px" }}>
                <HeaderText fontSize="10pt">
                    <a href="#" onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setShowDetails(!showDetails)
                    }} style={{ textDecoration: "none" }}>
                        {showDetails ? "Hide" : "Show"} Details
                    </a>
                </HeaderText>
            </Row>
        </>
    }

    return <div className="EventContainer" onClick={() => setShowDetails(true)}>
        <Row>
            <FancyHeaderText style={{
                fontSize: "38pt",
                padding: "20px",
            }}>
                {props.event.name}
            </FancyHeaderText>
        </Row>
        <Row className="justify-content-center EventSubsections">
            <Col sm={width} className="EventSubsection">
                <HeaderText fontSize="13pt" className="EventBlurbHeader">Date</HeaderText>
                {
                    props.event.date && props.event.date.split("\n").map((line, i) => {
                        if (i == 0) {
                            return <div>{line}</div>
                        }
                        return <div className="EventSmallText">{line}</div>
                    })
                }
            </Col>
            <hr/>
            <Col sm={width} className="EventSubsection">
                <HeaderText fontSize="13pt" className="EventBlurbHeader">Time</HeaderText>
                {
                    props.event.time && props.event.time.split("\n").map((line, i) => {
                        if (i == 0) {
                            return <div>{line}</div>
                        }
                        return <div className="EventSmallText">{line}</div>
                    })
                }
            </Col>
            <hr/>
            <Col sm={width} className="EventSubsection">
                <HeaderText fontSize="13pt" className="EventBlurbHeader">
                    Venue
                </HeaderText>
                {
                    props.event.location.split("\n").map((line, i) => {
                        if (i == 0) {
                            return <div>{line}</div>
                        }
                        return <div className="EventSmallText">{line}</div>
                    })
                }
            </Col>
        </Row>
        { props.event.name.includes("Reception") && <>
            <hr />
        <Row className="justify-content-center EventSubsections">
            <Col sm={width} className="EventSubsection">
                <HeaderText fontSize="13pt" className="EventBlurbHeader">Table</HeaderText>
                { props.event.table_assignment || "Check back later!" }
            </Col>
        </Row>
        </> }
        { detailsSection }
    </div>
}

function EventList(props) {
    if (props.eventList) {
        return <Container className="EventList">
        {
            props.eventList.map((event) =>
                <Event key={event.name} event={event} />
            )
        }
        </Container>
    } else {
        return <div>
            <p>An error occurred fetching events.</p>
            <p style={{ fontSize: "14pt" }}>Please try refreshing the page.</p>
        </div>
    }
}

export default function EventScheduleSection(props) {
    return <>
        <Section
            invert={true}
            className="EventSectionBG"
            sectionClassName="EventSection"
            backgroundHeight="4000px"
            isFixedHeight={false}>
            <TextSectionContent title="Schedule" style={{
                filter: "drop-shadow(2px 2px 5px black)",
                fontSize: "30pt",
            }}>
            {
                props.isLoadingEvents
                    ? <LoadingIndicator />
                    : <EventList eventList={props.eventList} />
            }
            </TextSectionContent>
        </Section>
    </>
}
