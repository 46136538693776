import React, { useEffect, useState } from 'react'

import './AuthenticationSection.css'
import API from '../API.js'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Section from '../Section.js'
import Spinner from 'react-bootstrap/Spinner'
import TextSectionContent from '../TextSectionContent.js'
import queryString from 'query-string'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useUserData, pluralize } from '../UserData.js'

function AuthenticatedContent(props) {
    const context = props.context
    const logOut = event => {
        event.preventDefault()
        context.setUserData(null)
    }

    useEffect(() => {
        context.setTriedPersonalizedLink(true)
        API.note(context.userData, "VH", null)
    }, [])

    let notYou
    const { search } = useLocation()
    const values = queryString.parse(search)
    if (!context.userData.isFromPersonalizedLink) {
        notYou = <Button variant="link" onClick={logOut} className="NotYouButton">
            (not you?)
        </Button>
    }

    return <>
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginTop: "30px",
            marginBottom: "30px",
        }}>
            <div style={{ paddingBottom: "20px" }}>
                Dear <strong>{context.userData.familiar_name}</strong>,
                {notYou}
            </div>
            <div style={{ fontSize: "15pt", maxWidth: "600px" }}>
                <p>As our { pluralize("guest", context.userData) }, you have access to our event schedule, and are invited to RSVP at your earliest convenience.</p>
            </div>
        </div>

        <Link to="/guests">
            <Button variant="primary" size="lg">
                View Schedule & RSVP
            </Button>
        </Link>
    </>
}

function UnauthenticatedContent(props) {
    const context = props.context
    const onSubmit = event => {
        if (event) {
            event.preventDefault()
        }
        tryAuthenticate(context.input, false)
    }

    const tryAuthenticate = (input, isFromPersonalizedLink) => {
        context.setLoading(true)
        context.setError(null)
        API.authenticate(input)
            .onSuccess(data => {
                if (data === "ambiguous") {
                    context.setUserData(null)
                    context.setError("Please include your last name, or use your email address instead.")
                    API.note(null, "AA", input)
                } else {
                    if (data && isFromPersonalizedLink) {
                        data.isFromPersonalizedLink = true
                    }
                    context.setUserData(data)
                    if (data) {
                        if (isFromPersonalizedLink) {
                            API.note(data, "PLS", input)
                        } else {
                            API.note(data, "AS", input)
                        }
                    } else {
                        context.setError("Please make sure you've entered your name or email correctly.")
                        if (isFromPersonalizedLink) {
                            API.note(null, "PLF", input)
                        } else {
                            API.note(null, "AF", input)
                        }
                    }
                }
            })
            .onError(() => {
                context.setError("An error occurred. Please try again.")
                context.setUserData(null)
            })
            .finally(() => context.setLoading(false))
            .send()
    }

    const { search } = useLocation()
    const values = queryString.parse(search)
    useEffect(() => {
        if (values.email && !context.triedPersonalizedLink) {
            context.setTriedPersonalizedLink(true)
            context.setInput(values.email)
            tryAuthenticate(values.email, true)
        }
    }, [])


    return <>
        To RSVP and view the event schedule, please enter your name or email address.

        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "30px",
        }}>
            <Form style={{ maxWidth:"500px" }} onSubmit={onSubmit}>
                <Form.Control type="text"
                    value={context.input}
                    onChange={e => context.setInput(e.target.value)}
                    placeholder="Name or email address"
                    disabled={context.isLoading}
                    style={{
                        fontSize:"20pt",
                        textAlign: "center",
                        marginBottom: "20px",
                    }} />
                {
                    context.error
                        ? <div style={{
                                fontSize: "12pt",
                                color: "red",
                                paddingBottom: "10px",
                            }}>{context.error}</div>
                        : null
                }
                <Button
                    variant="primary"
                    type="submit"
                    disabled={context.isLoading}
                    style={{ fontSize: "20pt", width: "200px" }}>
                    {
                        context.isLoading
                            ? <Spinner
                                as="span"
                                animation="border"
                                size="lg"
                                role="status" />
                            : <>Submit</>
                    }
                </Button>
            </Form>
        </div>
    </>
}

export default function AuthenticationSection(props) {
    const [userData, setUserData] = useUserData()
    const [input, setInput] = useState("")
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [triedPersonalizedLink, setTriedPersonalizedLink] = useState(false)

    const context = {
        userData: userData,
        setUserData: setUserData,
        input: input,
        setInput: setInput,
        isLoading: isLoading,
        setLoading: setLoading,
        error: error,
        setError: setError,
        triedPersonalizedLink: triedPersonalizedLink,
        setTriedPersonalizedLink: setTriedPersonalizedLink,
    }

    const { search } = useLocation()
    const values = queryString.parse(search)

    return <Section id={props.id} isFixedHeight={false}>
        <TextSectionContent title="Schedule & RSVP">
            {
                (userData && (!values.email || userData.email == values.email || context.triedPersonalizedLink))
                    ? <AuthenticatedContent context={context} />
                    : <UnauthenticatedContent context={context} />
            }
        </TextSectionContent>
    </Section>
}
