import { React, useEffect, useState } from 'react'

import './GuestArea.css'
import API from './API.js'
import NavBar from './NavBar.js'
import Section from './Section.js'
import { FancyHeaderText } from './HeaderText.js'
import { Link, Redirect } from 'react-router-dom';
import HamburgerContext from './HamburgerContext.js';
import { useUserData } from './UserData.js'
import { HeaderText } from './HeaderText.js';

import EventScheduleSection from './content/EventScheduleSection.js'
import { GuestNavigationLinks } from './content/NavigationLinks.js'
import RSVPFormSection from './content/RSVPFormSection.js'
import LogisticsSection from './content/LogisticsSection.js'
import RegistrySection from './content/RegistrySection.js'
import UpNextSection from './content/UpNextSection.js'

export default function GuestArea() {
    const [userData] = useUserData()
    const [eventList, setEventList] = useState(null)
    const [isLoadingEvents, setLoadingEvents] = useState(true)

    // Force scroll to top on mount because otherwise scroll position carries over.
    useEffect(() => window.scrollTo(0, 0), [])

    useEffect(() => {
        if (userData) {
            API.note(userData, "VG", null)
        }
    }, [])

    if (!userData) {
        return <Redirect to="/" />
    }

    if (isLoadingEvents) {
        API.events(userData)
            .onSuccess(events => {
                setEventList(events)
            })
            .onError(() => {
                setEventList(null)
            })
            .finally(() => setLoadingEvents(false))
            .send()
    }

    return <>
        <NavBar links={GuestNavigationLinks} autohide={true} />
        <HamburgerContext links={GuestNavigationLinks} />
        <Section
            className="GuestAreaHeader ForceBlurNavBar"
            sectionClassName="EventSection"
            backgroundImage="img/guest-top.jpg"
            backgroundHeight="200px"
            contain={true}
            isFixedHeight={false}>
            <FancyHeaderText>
                Welcome,
            </FancyHeaderText>
            <FancyHeaderText fontSize="40pt">
                {userData.familiar_name}.
            </FancyHeaderText>
        </Section>

        <UpNextSection
            userData={userData}
            eventList={eventList}
            isLoadingEvents={isLoadingEvents}
        />

        <div className="ForceBlurNavBar" id="schedule">
            <EventScheduleSection
                userData={userData}
                eventList={eventList}
                isLoadingEvents={isLoadingEvents}
            />
        </div>

        <LogisticsSection
            id="logistics"
            eventList={eventList}
            isLoadingEvents={isLoadingEvents}
        />

        <hr />


        <RegistrySection id="registry" />

        <hr/>

        <RSVPFormSection
            id="rsvp"
            eventList={eventList}
            isLoadingEvents={isLoadingEvents}
        />
    </>
}
