import React from 'react'

import Section from '../Section.js'
import SectionSubtitle from '../SectionSubtitle.js'
import TextSectionContent from '../TextSectionContent.js'
import { Blurb, BlurbContainer } from '../Blurb.js'

export default class DetailsSection extends React.Component {
    render() {
        return <Section id={this.props.id} height="400px" isFixedHeight={false}>
            <TextSectionContent>
                <SectionSubtitle>
                    <div>
                        <span style={{ display: "inline-block" }}>We're in the midst of planning</span> <span style={{ display: "inline-block" }}>our wedding for later this year.</span>
                    </div>
                    <div>Here are the key details.</div>
                </SectionSubtitle>

                <BlurbContainer>
                    <Blurb title="When" titleSpacing="15px">
                        <div style={{ fontSize: "20pt", height: "40px", whiteSpace: "nowrap" }}>
                            October 3rd
                        </div>
                        <div style={{ fontSize: "22pt", height: "30px", transform: "translateY(-8px)", whiteSpace: "nowrap" }}>
                            2021
                        </div>
                    </Blurb>
                    <Blurb title="Where" titleSpacing="15px">
                        <div style={{ fontSize: "20pt", height: "40px", whiteSpace: "nowrap" }}>
                            Kirigin Cellars
                        </div>
                        <div style={{ fontSize: "17pt", height: "30px", whiteSpace: "nowrap" }}>
                            Gilroy, California
                        </div>
                    </Blurb>
                    <Blurb title="Hosts" titleSpacing="15px">
                        <div>
                            <div style={{ fontSize: "20pt", height: "40px", whiteSpace: "nowrap" }}>
                                Banthia & Chimala
                            </div>
                        </div>
                        <div>
                            <div style={{ fontSize: "17pt", height: "30px", whiteSpace: "nowrap" }}>
                                Families
                            </div>
                        </div>
                    </Blurb>
                </BlurbContainer>
            </TextSectionContent>
        </Section>
    }
}
