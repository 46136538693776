import React from 'react'

import './FAQSection.css'
import Section from '../Section.js'
import SectionSubtitle from '../SectionSubtitle.js'
import TextSectionContent from '../TextSectionContent.js'

class Question extends React.Component {
    render() {
        return <div className="QuestionContainer">
            <div className="QuestionQ">Q.</div>
            <div className="Question">
                {this.props.children}
            </div>
        </div>
    }
}

class Answer extends React.Component {
    render() {
        return <>
            <div className="Answer">
                {this.props.children}
            </div>
        </>
    }
}

export default class FAQSection extends React.Component {
    render() {
        return <Section id={this.props.id} isFixedHeight={false} sectionClassName="FAQSection">
            <TextSectionContent title="Questions?">
                <SectionSubtitle />

                <Question>
                    How are you handling the COVID vaccine?
                </Question>
                <Answer>
                    <p><u>The weekend's festivities require all guests to be fully vaccinated against COVID-19.</u> We are also limiting the guest count to minimize risk. For your safety and ours, if you cannot get the vaccine, we ask that you meet us outside of this weekend's festivities to share your blessings in a socially distant way.</p>
                </Answer>

                <Question>
                    Are kids allowed?
                </Question>
                <Answer>
                    <p>While we love your little ones, in keeping with our venue's safety guidelines, our wedding will be an adults-only event. We hope this helps everyone relax and enjoy the evening. We appreciate you making arrangements ahead of time and leaving the kids at home before the celebration.</p>
                </Answer>

                <Question>
                    Can I bring a +1?
                </Question>
                <Answer>
                    <p>To maintain a safe guest count during the pandemic, we plan to only host those listed by name on your invitation. If you think we made a mistake and missed someone, please reach out to us at <a href="mailto:prachieandanshu@gmail.com">prachieandanshu@gmail.com</a>. Please do not extend the invitation without reaching out first.</p>
                </Answer>

                <Question>
                    Where should I park at Kirigin Cellars?
                </Question>
                <Answer>
                    <p>Please don't put "Kirigin Cellars" into your navigation app, or it'll send you to the wrong entrance. Navigate to <b>3255 Day Rd, Gilroy, CA 95020</b>.</p>
                </Answer>

                <Question>
                    What time should I arrive?
                </Question>
                <Answer>
                    <p>We recommend that you arrive a bit ahead of the 3pm start time if you want to join the Baraat and grab refreshments before the ceremony starts! Since the venue will close at 9:30pm sharp, we'll be keeping a tight schedule, so please be on time!</p>
                </Answer>

                <Question>
                    Where is the gift registry?
                </Question>
                <Answer>
                    <p>Your presence and blessings at our wedding are all we wish for, so please don't feel obligated to get us anything. If you do insist, please don't bring boxed gifts. Instead, <a href="https://www.honeyfund.com/wedding/chimala-banthia-10-03-2021">we have a honeymoon fund here</a>. Thank you very much!</p>
                </Answer>

                <Question>
                    What is the Baraat? Will there be elephants?
                </Question>
                <Answer>
                    <p>The Baraat is the groom's big entrance to his wedding. It will start right at 3pm. You won't want to miss it!</p>
                    <p>Traditionally, the groom and his entourage travel to the bride's home on a horse or elephant. Throughout the journey, everyone dances in celebration to cheer the groom on. Once they arrive, the bride's family performs the "Milni" ceremony to welcome their future son-in-law. This is the groom's chance to make a splash, before the beautiful bride gets all the attention!</p>
                    <p>Since Anshu's more into horsepower than horses, we'll be doing a modern interpretation, where he'll arrive in a flashy sportscar instead! Don't worry, there'll still be plenty of music and dancing surrounding him. Everyone's welcome to join in the excitement as Anshu makes his way to the ceremony, to stop him getting cold feet, celebrate with him on his big day, and help him make a memorable entrance!</p>
                </Answer>

                <Question>
                    What should I wear?
                </Question>
                <Answer>
                    <p>Anshu and Prachie will be in Indian formalwear for the wedding ceremony, and Western formalwear for the reception. So whether you choose sarees and kurtas or dresses and suits, you'll be in good company! Gilroy weather in early October averages 80F high/50F low (26C/10C), and the wedding festivities will be primarily held outdoors.</p>
                    <p>Please don't wear red, as this is traditionally the bride's color in an Indian wedding.</p>
                </Answer>

                <Question>
                    What should I do if I can't make it?
                </Question>
                <Answer>
                    <p>You will be missed! If you can't make it to the wedding, please let us know as soon as possible by RSVPing, so we can plan accordingly.</p>
                </Answer>

                <Question>
                    I still have questions, what is the best way to contact you?
                </Question>
                <Answer>
                    <p>Please feel free to reach out at <a href="mailto:prachieandanshu@gmail.com">prachieandanshu@gmail.com</a>.</p>
                </Answer>
            </TextSectionContent>
        </Section>
    }
}
