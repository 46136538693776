import React from 'react'

export function pluralize(word, userData) {
    return userData.num_people > 1 ? (word + "s") : word
}

export function I(userData, options) {
    return userData.num_people > 1 ? (options && options.titleCase ? "We" : "we") : "I"
}

export function useUserData() {
    const existingData = localStorage.getItem("userData")
    const [value, setValue] = React.useState(
        existingData ? JSON.parse(existingData) : null
    )
    React.useEffect(() => {
        if (value) {
            localStorage.setItem("userData", JSON.stringify(value))
        } else {
            localStorage.removeItem("userData")
        }
    }, [value])
    return [value, setValue]
}
