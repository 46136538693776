import React, { useState } from 'react'

import './EventScheduleSection.css'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Section from '../Section.js'
import Spinner from 'react-bootstrap/Spinner'
import TextSectionContent from '../TextSectionContent.js'
import countdown from 'countdown'
import { Blurb, BlurbContainer } from '../Blurb.js'
import { FancyHeaderText } from '../HeaderText.js'
import { HeaderText } from '../HeaderText.js'
import { detailsForEvent } from './EventScheduleSection.js'

function LoadingIndicator() {
    return <div style={{ height: "1000px" }}>
        <Spinner as="span" animation="border" size="lg" role="status" />
    </div>
}

function formatRelative(date) {
    return "starts in " + countdown(new Date(), date, countdown.DAYS | countdown.HOURS).toString()
}

function NextEvent(props) {
    let width = 4

    return <>
        <Row className="justify-content-center">
            <FancyHeaderText style={{
                fontSize: "38pt",
                paddingTop: "20px",
            }}>
                {props.event.name}
            </FancyHeaderText>
            { formatRelative(Date.parse(props.event.start_datetime)) }
        </Row>


        <Row className="justify-content-center EventSubsections" style={{ margin: "30px 0 30px 0" }}>
            <Col sm={width} className="EventSubsection">
                <HeaderText fontSize="13pt" className="EventBlurbHeader">Date</HeaderText>
                {
                    props.event.date && props.event.date.split("\n").map((line, i) => {
                        if (i == 0) {
                            return <div>{line}</div>
                        }
                        return <div className="EventSmallText">{line}</div>
                    })
                }
            </Col>
            <hr/>
            <Col sm={width} className="EventSubsection">
                <HeaderText fontSize="13pt" className="EventBlurbHeader">Time</HeaderText>
                {
                    props.event.time && props.event.time.split("\n").map((line, i) => {
                        if (i == 0) {
                            return <div>{line}</div>
                        }
                        return <div className="EventSmallText">{line}</div>
                    })
                }
            </Col>
            <hr/>
            <Col sm={width} className="EventSubsection">
                <HeaderText fontSize="13pt" className="EventBlurbHeader">
                    Venue
                </HeaderText>
                {
                    props.event.location.split("\n").map((line, i) => {
                        if (i == 0) {
                            return <div>{line}</div>
                        }
                        return <div className="EventSmallText">{line}</div>
                    })
                }
            </Col>
        </Row>
        { props.event.name.includes("Reception") && <>
            <hr />
        <Row className="justify-content-center EventSubsections">
            <Col sm={width} className="EventSubsection">
                <HeaderText fontSize="13pt" className="EventBlurbHeader">Table</HeaderText>
                { props.event.table_assignment || "Check back later!" }
            </Col>
        </Row>
        </> }
        <Row className="EventDetails">
            { detailsForEvent(props.event) }
        </Row>
    </>
}

export default function UpNextSection(props) {
    if (props.isLoadingEvents) {
        return <Section isFixedHeight={false}>
            <LoadingIndicator />
        </Section>
    }
    let nextEvent
    if (!props.isLoadingEvents) {
        for (const event of props.eventList) {
            if (Date.parse(event.start_datetime) < new Date()) {
                // Event is over; skip it
                continue
            }
            if (!nextEvent || Date.parse(event.start_datetime) < Date.parse(nextEvent.start_datetime)) {
                nextEvent = event
            }
        }
    }

    if (!nextEvent) {
        return <Section isFixedHeight={false}>
            <TextSectionContent title="Thank you!">
                <Row className="justify-content-center">
                    <div style={{ fontSize: "15pt", maxWidth: "700px" }}>
                        <p>
                            The wedding is now over. We're so grateful for all of you who were able to make it, and hope you had a
                            blast celebrating with us. We can't wait to create more wonderful memories with you in our new married
                            lives!
                        </p>
                        <p>
                            With love,
                        </p>
                        <p>
                            <FancyHeaderText style={{
                                fontSize: "25pt",
                            }}>
                                Prachie & Anshu
                            </FancyHeaderText>
                        </p>
                    </div>
                </Row>
            </TextSectionContent>
        </Section>
    }

    return <Section isFixedHeight={false}>
        <TextSectionContent title="Next Event">
            {
                props.isLoadingEvents
                    ? <LoadingIndicator />
                    : <NextEvent event={nextEvent} />
            }
        </TextSectionContent>
    </Section>
}
