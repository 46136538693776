class APICallBuilder {
    constructor(makeCall) {
        this.makeCall = makeCall
    }

    onSuccess(handler) {
        this.onSuccess = handler
        return this
    }

    onError(handler) {
        this.onError = handler
        return this
    }

    finally(handler) {
        this.finally = handler
        return this
    }

    send() {
        this.makeCall({
            onSuccess: this.onSuccess,
            onError: this.onError,
            finally: this.finally,
        })
    }
}

export default class API {
    static url(path) {
        if (process.env.NODE_ENV == "development") {
            return "http://localhost:8000/api" + path
        }
        return "https://api.prachieandanshu.com/api" + path
    }

    static authenticate(name) {
        return new APICallBuilder((context) => {
            fetch(this.url("/authenticate"), {
                method: "POST",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name_or_email: name,
                })
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    if (data.result.length === 1) {
                        context.onSuccess(data.result[0])
                    } else if (data.result.length > 1) {
                        context.onSuccess("ambiguous")
                    } else {
                        context.onSuccess(null)
                    }
                } else {
                    console.log("API Error:", data.error)
                    context.onError()
                }
            })
            .catch(error => {
                console.log("API call error:", error)
                context.onError()
            })
            .finally(context.finally)
        })
    }

    static events(userData) {
        return new APICallBuilder((context) => {
            fetch(this.url("/events"), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    guest_id: userData.id,
                })
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    context.onSuccess(data.result)
                } else {
                    console.log("API Error:", data.error)
                    context.onError()
                }
            })
            .finally(context.finally)
        })
    }

    static submitRSVP(userData, willAttend, fullyVaccinated, allAttending, perEventRSVPs, message) {
        return new APICallBuilder((context) => {
            fetch(this.url("/rsvp"), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    guest_id: userData.id,
                    will_attend: !!willAttend,
                    fully_vaccinated: !!fullyVaccinated,
                    all_attending: !!allAttending,
                    per_event_rsvps: perEventRSVPs,
                    message: message || "",
                })
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    context.onSuccess(data.result)
                } else {
                    console.log("API Error:", data.error)
                    context.onError()
                }
            })
            .finally(context.finally)
        })
    }

    static lastRSVP(userData) {
        return new APICallBuilder((context) => {
            fetch(this.url("/rsvp/last"), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    guest_id: userData.id,
                })
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    context.onSuccess(data.result)
                } else {
                    console.log("API Error:", data.error)
                    context.onError()
                }
            })
            .finally(context.finally)
        })
    }

    static note(userData, kind, ctx) {
        fetch(this.url("/note"), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                guest_id: userData ? userData.id : "none",
                kind: kind,
                context: ctx,
            })
        })
        .then(response => response.json())
        .then(data => {
            if (!data.success) {
                console.log("API Error:", data.error)
            }
        })
    }

    static dashboard(password) {
        return new APICallBuilder((context) => {
            fetch(this.url("/rsvp/dashboard"), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    password: password,
                })
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    context.onSuccess(data.result)
                } else {
                    console.log("API Error:", data.error)
                    context.onError()
                }
            })
            .finally(context.finally)
        })
    }
}
