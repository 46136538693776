class NavigationLink {
    constructor(title, url) {
        this.title = title
        this.url = url
    }
}

const NavigationLinks = [
    new NavigationLink("RSVP", "#rsvp"),
    new NavigationLink("Our Story", "#story"),
    new NavigationLink("Details", "#details"),
    new NavigationLink("FAQ", "#faq"),
]

export const GuestNavigationLinks = [
    new NavigationLink("Home", "/"),
    new NavigationLink("Schedule", "#schedule"),
    new NavigationLink("Info", "#logistics"),
    new NavigationLink("Registry", "#registry"),
]

export default NavigationLinks
