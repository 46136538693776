import React from 'react';
import { HeaderText } from './HeaderText.js';

class TextSectionContent extends React.Component {
    render() {
        return <>
            { this.props.title &&
                <HeaderText style={{
                    paddingBottom: "30px",
                    ...this.props.style
                }}>
                    { this.props.title }
                </HeaderText>
            }
            <span className="body">
                { this.props.children }
            </span>
        </>;
    }
}

export default TextSectionContent;
