import React, {useState, useEffect} from 'react'

import API from './API.js'
import BootstrapTable from 'react-bootstrap-table-next';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'

function usePassword() {
    const existingData = localStorage.getItem("dashboardPassword")
    const [value, setValue] = React.useState(existingData)
    React.useEffect(() => {
        if (value) {
            localStorage.setItem("dashboardPassword", value)
        } else {
            localStorage.removeItem("dashboardPassword")
        }
    }, [value])
    return [value, setValue]
}

export default function Dashboard() {
    const [password, setPassword] = usePassword()
    const [error, setError] = useState(null)
    const [attempt, setAttempt] = useState(null)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)

    function load(pass) {
        setLoading(true)
        API.dashboard(pass)
            .onSuccess(data => {
                setData(data)
                setPassword(pass)
            })
            .onError(() => {
                setData(null)
                setPassword(null)
                setError("Incorrect, try again")
            })
            .finally(() => setLoading(false))
            .send()
    }

    const onSubmit = event => {
        event.preventDefault()
        setError(null)
        load(attempt)
    }

    useEffect(() => {
        if (!data && password) {
            load(password)
        }
    }, [])

    if (!password) {
        return <Form onSubmit={onSubmit}>
            <Form.Control type="text" placeholder="Password" onChange={e => setAttempt(e.target.value)} />
            <div style={{ color: "red" }}>{error}</div>
            <Button variant="primary" type="submit">
                Submit
            </Button>
        </Form>
    }

    if (loading || !data) {
        return <Spinner
            as="span"
            animation="border"
            size="lg"
            role="status" />
    }

    let totals = {}

    let rows = data.data.map(datum => {
        let rsvp
        if (datum.rsvp === true) {
            rsvp = <span style={{ color: "green" }}>Yes</span>
        } else if (datum.rsvp === false) {
            rsvp = <span style={{ color: "red" }}>No</span>
        } else {
            rsvp = "-"
        }

        let hasSeen
        if (datum.has_seen === true) {
            hasSeen = <span style={{ color: "green" }}>Yes</span>
        } else {
            hasSeen = <span style={{ color: "red" }}>No</span>
        }
        const sources = {
            "A": "Anshu",
            "P": "Prachie",
            "AP": "Anshu's Parents",
            "PP": "Prachie's Parents",
            "NV": "(No Visa)",
        }
        var row = {
            name: datum.name,
            source: sources[datum.source],
            rsvp: rsvp,
            hasSeen: hasSeen,
        }
        data.all_events.forEach(event => {
            if (!totals[event.pk]) {
                totals[event.pk] = [0, 0, 0]
            }
            let rsvp
            if (!datum.invited.includes(event.pk)) {
                rsvp = <span style={{ color: "gray", fontSize: "12pt" }}>not invited</span>
            } else if (datum.rsvp === false || (datum.will_not_attend && datum.will_not_attend.includes(event.pk))) {
                rsvp = <span style={{ color: "red" }}>No</span>
                ++totals[event.pk][1]
            } else if (datum.rsvp === true) {
                rsvp = <span style={{ color: "green" }}>Yes</span>
                ++totals[event.pk][0]
            } else {
                rsvp = "-"
                ++totals[event.pk][2]
            }
            row["invited_" + event.pk] = rsvp
        })
        if (!totals["rsvp"]) {
            totals["rsvp"] = [0, 0, 0]
        }
        if (datum.rsvp === true) {
            ++totals["rsvp"][0]
        } else if (datum.rsvp === false) {
            ++totals["rsvp"][1]
        } else {
            ++totals["rsvp"][2]
        }
        return row
    })

    let totalsRow = {
        rsvp: <>
            <div style={{ color: "green" }}>Yes: {totals["rsvp"][0]}</div>
            <div style={{ color: "red" }}>No: {totals["rsvp"][1]}</div>
            <div>Pending: {totals["rsvp"][2]}</div>
        </>
    }

    data.all_events.forEach(event => {
        totalsRow[event.pk] = <>
            <div style={{ color: "green" }}>Yes: {totals[event.pk][0]}</div>
            <div style={{ color: "red" }}>No: {totals[event.pk][1]}</div>
            <div>Pending: {totals[event.pk][2]}</div>
        </>
    })

    let columns = [
        {
            dataField: 'name',
            text: 'Guest',
            footer: "",
            headerFormatter: () => <div onClick={() => alert("hi")}>Guest</div>
        },
        {
            dataField: 'source',
            text: 'Invited By',
            footer: "",
        },
        {
            dataField: 'rsvp',
            text: 'RSVP',
            footer: "",
            footerFormatter: () => totalsRow["rsvp"],
        },
        {
            dataField: 'hasSeen',
            text: 'Seen Website?',
            footer: "",
        },
    ].concat(data.all_events.map(event => ({
        dataField: "invited_" + event.pk,
        text: event.name,
        footer: "",
        footerFormatter: () => totalsRow[event.pk],
    })))

    return <div style={{ fontSize: "12pt" }}>
        <BootstrapTable keyField='name'
            data={ rows }
            columns={ columns }
            hover={true}
        />
    </div>
}
