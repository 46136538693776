import React from 'react';

class FancyHeaderText extends React.Component {
    render() {
        return <div
            className={this.props.className}
            style={{
                fontFamily: "sloop-script-three",
                fontWeight: 700,
                fontStyle: "normal",
                fontSize: this.props.fontSize,
                lineHeight: 1,
                ...this.props.style,
            }}>
            { this.props.children }
        </div>
    }
}

class HeaderText extends React.Component {
    render() {
        return <div
            className={this.props.className}
            style={{
                fontFamily: "quasimoda",
                textTransform: "uppercase",
                fontWeight: 400,
                fontStyle: "normal",
                fontSize: `calc(${this.props.fontSize || "22pt"})`,
                letterSpacing: `calc(${ this.props.fontSize || "22pt" } * 0.35)`,
                marginLeft: `calc(${ this.props.fontSize || "22pt" } * 0.35)`,
                ...this.props.style,
            }}>
            { this.props.children }
        </div>
    }
}

export { FancyHeaderText, HeaderText };
