import React from 'react';

import Container from 'react-bootstrap/Container';
import NavLink from './NavLink.js';
import Row from 'react-bootstrap/Row';
import Section from './Section.js';
import { FancyHeaderText } from './HeaderText.js';

import NavigationLinks from './content/NavigationLinks.js';

class HeroContent extends React.Component {
    render() {
        return <FancyHeaderText className="HeroContent">
            Prachie & Anshu
        </FancyHeaderText>;
    }
}

class ScrollChevron extends React.Component {
    render() {
        return <img
            src="./img/chevron-down.svg"
            alt="Scroll down to continue"
            className="bounce"
            style={{
                width: "140px",
                filter: "invert(100%) drop-shadow(2px 2px 15px rgba(0, 0, 0, 0.8))",
                position: "absolute",
                left: "calc(50% - 140px / 2)",
                top: "calc(100% - 200px)",
            }} />
    }
}

export default class HeroSection extends React.Component {
    render() {
        return <Section
            id="HeroSection"
            className="HeroSection"
            backgroundImage="img/hero.jpg"
            horizontalOffset="70%"
            auxiliaryContent={ <ScrollChevron /> }>
            <HeroContent />
            <Container style={{ paddingTop: "30px" }}>
                <Row className="justify-content-center gx-5" id="HeroLinks">
                    {
                        NavigationLinks.map((link, i) => {
                            return <NavLink
                                key={link.url}
                                href={link.url}
                                className="Hero"
                                fontSize="16pt">
                                {link.title}
                            </NavLink>
                        })
                    }
                </Row>
            </Container>
        </Section>
    }
}