import React, { useState, useEffect } from 'react'
import { useUserData, I } from '../UserData.js'
import TextSectionContent from '../TextSectionContent.js'
import Section from '../Section.js'
import Spinner from 'react-bootstrap/Spinner'
import './FAQSection.css'

function hasEvent(eventList, name) {
    return eventList.some((e) => e.name.includes(name))
}

function cohort(eventList) {
    let haldi = hasEvent(eventList, "Haldi")
    let sangeet = hasEvent(eventList, "Sangeet")
    let afterparty = hasEvent(eventList, "Afterparty")
    if (haldi && sangeet && afterparty) { return "HSA" }
    // if (sangeet && !afterparty) { return "SWR" }
    if (afterparty) { return "WRA" }
    return "WR"
}

class Question extends React.Component {
    render() {
        return <div className="QuestionContainer">
            <div className="QuestionQ">Q.</div>
            <div className="Question">
                {this.props.children}
            </div>
        </div>
    }
}

class Answer extends React.Component {
    render() {
        return <>
            <div className="Answer">
                {this.props.children}
            </div>
        </>
    }
}

function HaldiSection() {
    return <>
        <Question>
            Where should I stay throughout the weekend?
        </Question>
        <Answer>
            <p>If you’re looking to get a hotel, we’d recommend staying around downtown San Jose. From there, every event is about 20 minutes away by car.</p>
            <p>You can also stay at our house in Sunnyvale! It's centrally located, it's free, and it has all the amenities and trademark Prachie & Anshu interior design charm you'd expect. However, you'll have to sleep on a sofa-bed or air mattress. Reach out to Prachie at 408-781-6994 if you’d like to do this; you are most welcome!</p>
        </Answer>
    </>
}

function AfterpartySection() {
    return <>
        <Question>
            What's the plan for afterpartying responsibly?
        </Question>
        <Answer>
            <p>Since we'll be serving drinks and staying up late Sunday night, we don't encourage driving home after. We'll have some sleeping bags, so you're welcome to crash at Reeti’s house (the afterparty venue). Just let us know if you plan to do this!</p>
            <p>Alternatively, there are nearby hotels in Milpitas, or you can Uber home from the afterparty. You can leave your car at Reeti's and pick it up the next day; there's plenty of street parking!</p>
            <p>We are also providing a PARTY BUS from the wedding venue to the afterparty, so you can enjoy the wine at the
                reception and not worry about driving! We'd recommend Uber/Lyft/carpool to the venue so you can ride the party bus
                to the afterparty.</p>
        </Answer>
    </>
}

function WeddingSectionNoAfterparty() {
    return <>
        <Question>
            How can I get to the wedding venue?
        </Question>
        <Answer>
            <p>We'd recommend Uber/Lyft or carpooling. If you want to carpool, <a href="https://docs.google.com/spreadsheets/d/1sSvZYHHQwJkqHL22JteEMoJmOBUm1zlroJste6twdpo/edit#gid=0">we’ve created this spreadsheet you can use to self-organize</a>. Please add yourself to the spreadsheet if you need a ride or can offer one, and reach out to the contacts there directly to coordinate. (We are not organizing this beyond providing the spreadsheet).</p>
        </Answer>
    </>
}

function WeddingSectionAfterparty() {
    return <>
        <Question>
            How can I get to the wedding venue and afterparty?
        </Question>
        <Answer>
            <p>We'd recommend Uber/Lyft or carpooling. If you want to carpool, <a href="https://docs.google.com/spreadsheets/d/1sSvZYHHQwJkqHL22JteEMoJmOBUm1zlroJste6twdpo/edit#gid=0">we’ve created this spreadsheet you can use to self-organize</a>. Please add yourself to the spreadsheet if you need a ride or can offer one, and reach out to the contacts there directly to coordinate. (We are not organizing this beyond providing the spreadsheet).</p>
            <p>
                If you're joining us for the afterparty, we'll have a party bus bringing people from the wedding venue straight there! You can leave your car at Reeti's house (the afterparty venue) and Uber/Lyft to Kirigin Cellars from there, and take the bus back. Or, if you carpool to Kirigin Cellars, you can take the bus back and Uber/Lyft from Reeti's.
            </p>
        </Answer>
    </>
}

function WeddingOnlyHotel() {
    return <>
        <Question>
            Where should I stay in Gilroy?
        </Question>
        <Answer>
            As most of our guests are local, and with all of the uncertainties of the pandemic, we decided not to book a hotel block. If you are looking for a hotel in Gilroy, we recommend <b>Best Western Plus Forest Park</b>. A few guests are booking rooms there, so you may see some friends! This hotel is a 12 minute drive from the venue.
        </Answer>
    </>
}

export default function LogisticsSection(props) {
    const [userData] = useUserData()

    if (props.isLoadingEvents) {
        return <Section id={props.id} isFixedHeight={false}>
            <TextSectionContent title="Important Information">
                <Spinner
                    as="span"
                    animation="border"
                    size="lg"
                    role="status" />
            </TextSectionContent>
        </Section>
    }

    const c = cohort(props.eventList)

    return <Section id={props.id} isFixedHeight={false}>
        <TextSectionContent title="Important Information">
        <div style={{
            textAlign: "left",
            fontSize: "13pt",
        }}>
            <p style={{
                borderRadius: "10px",
                border: "solid 1px rgba(0, 0, 0, 0.3)",
                padding: "10px",
                marginBottom: "40px",
            }}>If you're visiting from out of town, please take a COVID test before coming to the venue, out of an abundance of caution. Walgreens and CVS have self-tests available over the counter.</p>
            { c == "HSA" && <HaldiSection /> }
            { ["HSA", "WRA"].includes(c) ? <WeddingSectionAfterparty /> : <WeddingSectionNoAfterparty /> }
            { ["HSA", "WRA"].includes(c) && <AfterpartySection /> }
            { c == "WR" && <WeddingOnlyHotel /> }
        </div>
        </TextSectionContent>
    </Section>
}
