import './Section.css';

import React from 'react';
import { Parallax, Background } from 'react-parallax';

class SectionContent extends React.Component {
    render() {
        if (this.props.isFixedHeight ?? true) {
            return <div
                className={ this.props.className }
                style={{ height: this.props.height }}>
                <div className="SectionContent" style={{
                    position: 'relative',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: this.props.foregroundColor,
                    width: "100%",
                }}>
                    { this.props.children }
                </div>
            </div>;
        }

        return <div className={ this.props.className }
            style={{
                minHeight: this.props.height,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
            <div className={`SectionContent ${this.props.sectionClassName}`} style={{
                color: this.props.foregroundColor,
                width: "100%",
                maxWidth: "1400px",
            }}>
                { this.props.children }
            </div>
        </div>;
    }
}

class Section extends React.Component {
    render() {
        return <div id={this.props.id}>
            <Parallax strength={200}>
                <Background>
                    <div
                        className={`${this.props.className} ${(this.props.backgroundImage || this.props.invert) && "Section--backgroundImage"}`}
                        style={{
                            // This is weird, and ideally would just size based on the height of
                            // the image, but there's no great way to do this and we need it to
                            // be sufficiently large to not have cracks between parallax sections.
                            height: `calc(max(100vh, ${ this.props.backgroundHeight || this.props.height }))`,
                            width: "100vw",
                            background: this.props.backgroundImage ? `url(${ this.props.backgroundImage }) no-repeat` : null,
                            backgroundSize: this.props.contain ? "" : "cover",
                            backgroundPosition: `${ this.props.horizontalOffset } 50%`,
                        }}
                    />
                </Background>
                <SectionContent
                    sectionClassName={ this.props.sectionClassName }
                    foregroundColor={ (this.props.backgroundImage || this.props.invert) ? "white" : "black"}
                    { ...this.props }>
                    { this.props.children }
                </SectionContent>
                { this.props.auxiliaryContent }
            </Parallax>
        </div>
    }
}

export default Section;
