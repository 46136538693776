import React from 'react'

export default class Subtitle extends React.Component {
    render() {
        return <div style={{
            paddingBottom: "60px",
        }}>
            { this.props.children }
        </div>;
    }
}