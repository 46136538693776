import { React, useEffect } from 'react'

import HamburgerContext from './HamburgerContext.js';
import HeroSection from './HeroSection.js';
import NavBar from './NavBar.js';
import Section from './Section.js';
import { HeaderText } from './HeaderText.js';

import AuthenticationSection from './content/AuthenticationSection.js'
import DetailsSection from './content/DetailsSection.js';
import FAQSection from './content/FAQSection.js';
import StorySection from './content/StorySection.js';

export default function Home() {
    // Force scroll to top on mount because otherwise scroll position carries over.
    useEffect(() => window.scrollTo(0, 0), [])

    return <>
        <HeroSection />

        <NavBar autohide={true} />

        <HamburgerContext />

        <AuthenticationSection id="rsvp" />

        <Section backgroundImage="img/kirigin-2.jpg" height="550px" horizontalOffset="40%">
            <HeaderText
                fontSize="min(12vw, 50pt)"
                className="Interstitial"
                style={{
                    textShadow: "2px 2px 30px rgba(0, 0, 0, 1)",
                }}>
                Time to party
            </HeaderText>
        </Section>

        <StorySection id="story" />

        <Section
            backgroundImage="img/she-said-yes.jpg"
            height="700px"
            horizontalOffset="50%">
            <HeaderText
                fontSize="min(12vw, 50pt)"
                className="Interstitial"
                style={{
                    textShadow: "2px 2px 30px rgba(0, 0, 0, 1)",
                }}>
                She said yes!
            </HeaderText>
        </Section>

        <DetailsSection id="details" />

        <Section
            backgroundImage="img/prachie-anshu-champagne.jpg"
            height="650px"
            horizontalOffset="50%">
            <HeaderText
                fontSize="min(12vw, 50pt)"
                className="Interstitial"
                style={{
                    textShadow: "2px 2px 30px rgba(0, 0, 0, 1)",
                }}>
                Cheers!
            </HeaderText>
        </Section>

        <FAQSection id="faq" />
    </>;
}
