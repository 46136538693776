import React from 'react'

import Dashboard from './Dashboard.js'
import GuestArea from './GuestArea.js'
import Home from './Home.js'
import Section from './Section.js';
import { HeaderText } from './HeaderText.js';
import { Switch, Route } from 'react-router-dom'

export default function Main() {
    return <>
        <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/guests' component={GuestArea} />
            <Route exact path='/dashboard' component={Dashboard} />
        </Switch>

        <div style={{
            paddingTop: "20px",
            paddingBottom: "40px",
        }}>
            <HeaderText
                fontSize="14pt"
                style={{
                    paddingBottom: "20px",
                }}>
                <span style={{ display: "inline-block" }}>Designed by</span> <span style={{ display: "inline-block" }}>Anshu Chimala</span>
            </HeaderText>

            <span style={{
                fontSize: "12pt",
                lineHeight: "8px",
                paddingBottom: "10px",
            }}>
                <span>&copy; Anshu Chimala & Prachie Banthia 2021</span>
                <span className="footerSeparator">&bull;</span>
                <span className="footerSeparator-compact"><br/></span>
                <span>Photography by <a href="https://www.karenpavonephotography.com">Karen Pavone</a> & Sashi Chimala</span>
                <span className="footerSeparator">&bull;</span>
                <span className="footerSeparator-compact"><br/></span>
                <span><a href="mailto:prachieandanshu@gmail.com">Contact Us</a></span>
            </span>
        </div>
    </>
}
