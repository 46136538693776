import React from 'react';

import Hamburger from './Hamburger.js'
import OverlayMenu from './OverlayMenu.js'

export default class HamburgerContext extends React.Component {
    state = { isShown: false }

    setShown = isShown => {
        this.setState({ isShown: isShown })
    }

    render() {
        return <>
            <Hamburger isShown={this.state.isShown} setShown={this.setShown} />
            <OverlayMenu isShown={this.state.isShown} setShown={this.setShown} links={this.props.links} />
        </>
    }
}
