import React from 'react'

import './NavLink.css'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom';
import { HeaderText } from './HeaderText.js'

export default class NavLink extends React.Component {
    render() {
        const link = <Col md="auto">
            <a href={this.props.href} onClick={this.props.onClick} className={`NavLink ${this.props.className}`}>
                 <HeaderText fontSize={this.props.fontSize || "12pt"}>
                     {this.props.children}
                 </HeaderText>
             </a>
         </Col>
        if (this.props.href == "/") {
            return <Link to="/">{link}</Link>
        }
        return link
    }
}
